import { merge, translateTheme } from "../components/themableUI/utilities/themify"

const headerHeight = "100px"
const footerHeight = "50px"

const colors = {
  darkPurple: "#616C8C",
  lightPurple: "#95A0BF",
  darkGreen: "#7C8C5A",
  lightGreen: "#D3D9C5",
  offWhite: "#F2F2F2",
  grey10: "#E5E5E5",

  grey90: "#28282A",
}

const space = {
  sm: "0.5rem",
  md: "1rem",
  lg: "1.5rem",
  xl: "2rem",
  squash: {
    xs: "0.35rem 0.5rem",
    sm: "0.35rem 0.75rem",
    md: "0.35rem 0.1rem",
    lg: "0.35rem 1.5rem",
  },
  stretch: {
    md: "0.75rem 1rem",
    lg: "1.5rem 1rem",
  },
}

let defaultTheme = {
  backgroundColor: colors.offWhite,
  textColor: colors.grey90,
  textColorAdjust: "gray",
  border: "1px ridge black",
  color1: colors.lightGreen,
  color1Adjust: colors.darkGreen,
  color2: colors.lightPurple,
  color2Adjust: colors.darkPurple,

  contactForm: {
    styles: {},
  },
}
let darkTheme = {
  backgroundColor: "black",
  textColor: colors.grey10,
  textColorAdjust: "gray",
  border: "3px ridge white",
  color1: colors.darkGreen,
  color1Adjust: colors.lightGreen,
  color2: colors.darkPurple,
  color2Adjust: colors.lightPurple,

  button: {
    styles: {
      color: "textColor",
      backgroundColor: "color2",
      "&:hover": {
        color: "textColor",
        backgroundColor: "color2Adjust",
      },
    },
  },
  link: {
    styles: {
      color: "color2Adjust",
      "&:hover": {
        color: colors.offWhite,
      },
    },
  },
  nav: {
    styles: {
      a: {
        "&:hover": {
          color: colors.lightPurple,
        },
      },
    },
  },
}

const baseTheme = {
  backgroundColor: "white",
  color: "black",
  headerHeight: headerHeight,
  footerHeight: footerHeight,
  borderRadius: "0",
  focusBoxShadow: "0 0 0 0.25rem gray",
  link: {
    styles: {
      color: colors.darkGreen,
      "&:hover": {
        color: "black",
      },
    },
  },
  container: {
    isFluidBelow: "lg",
    maxWidths: {
      // sm: "540px",
      // md: "720px",
      lg: "960px",
      // xl: "1140px",
      // xxl: "1320px",
    },
  },
  row: {
    styles: {
      alignItems: "center",
    },
  },

  header: {
    styles: { backgroundColor: "backgroundColor" },
  },
  logo: {
    styles: {
      marginRight: space.lg,
    },
  },
  nav: {
    styles: {
      // marginRight: "-0.75rem",
      a: {
        fontFamily: "'Manrope', sans-serif",
        textTransform: "uppercase",
        "&:hover": {
          color: colors.darkPurple,
          "&.is-active": {
            borderBottomColor: colors.darkPurple,
          },
        },

        borderBottom: "2px solid white",
        borderBottomColor: "backgroundColor",

        transition: "border-bottom-color 0.5s linear, color 0.35s linear",

        inset: space.sm,
        "@media(min-width: 440px)": {
          inset: space.md,
        },
        "@media(min-width: 540px)": {
          inset: space.lg,
        },
        color: "inherit",
        "&.is-active": {
          borderBottomColor: "textColor",
        },

        "&:first-child": {
          paddingLeft: "0",
        },
        "&:last-child": {
          paddingRight: "0",
        },
      },
    },
  },
  button: {
    styles: {
      fontFamily: "'Manrope', sans-serif",
      textTransform: "uppercase",

      backgroundColor: "color2Adjust",
      color: "backgroundColor",
      borderRadius: "borderRadius",
      inset: space.squash.lg,
      boxShadow: `2px 2px ${colors.darkGreen}`,

      "&:hover": {
        color: "white",
        backgroundColor: "color2",
      },
    },
  },
  contactForm: {
    styles: {
      backgroundColor: colors.darkGreen,
      opacity: "0.90",
      inset: space.stretch.lg,
    },
  },
  input: {
    styles: {
      backgroundColor: colors.darkGreen,
      color: "inherit",
      borderRadius: "borderRadius",
      border: "none",
      borderBottom: "border",
      "&:focus": {
        backgroundColor: colors.darkGreen,
      },
      stack: space.md,
    },
    textarea: {
      styles: {
        minHeight: "calc(4.5em + 0.75rem + 2px)",
        stack: space.lg,
      },
    },
    label: {
      styles: {
        fontFamily: "'Manrope', sans-serif",
        textTransform: "uppercase",
      },
    },
  },
}

defaultTheme = translateTheme(merge(baseTheme, defaultTheme))
darkTheme = translateTheme(merge(baseTheme, darkTheme))

export { defaultTheme, darkTheme as nightTheme }
