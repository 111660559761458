import { createGlobalStyle } from "styled-components"
import { getObjectStyles } from "../components/themableUI//utilities/themify"

const GlobalStyle = createGlobalStyle`

  body {
    margin: 0;
    padding: 0;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 200;
    background: ${props => props.theme.backgroundColor || "white"};
    color: ${props => props.theme.textColor || "black"};

    /* font-family: Open-Sans, Helvetica, Sans-Serif; */
  }

  a {

    ${props =>
      props.theme.a && props.theme.a.styles
        ? getObjectStyles(props.theme.a.styles)
        : ""}

  }

  p, span {
    font-size: 1.2rem;
  }

/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}


  #hero, #about, #contact {
    display: flex;
    align-items: center;
  }

  #hero {
    min-height: calc( 100vh - ${props => props.theme.headerHeight});
  }
  #about {
    min-height: 100vh;
    padding-top: ${props => props.theme.headerHeight};
  }
  #contact {
    min-height: calc( 100vh - ${props => props.theme.footerHeight});
    padding-top: ${props => props.theme.headerHeight};

  }

  .heroText {
    font-size: 2.5rem;
    font-weight: 200;

    margin-bottom: 1.5rem;
  }
  .aboutText {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
  .contactText {
    margin-top: 2rem;
    font-size: 4rem;
    font-weight: 200;

    margin-bottom: 1.5rem;

  }

  .work-post {
    h3, h4, h5, h6 {
      font-weight: 300;
    }
    * {
      margin-bottom: 1.5rem;
    }
  }

  .gatsby-resp-image-wrapper {
  border-radius: 30px;
  border: 6px solid ${props => props.theme.color1Adjust};
  overflow: hidden;
}

.mobile-images {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mobile-images > * {
  width: 100%;
}

@media (min-width: 450px) {
  .mobile-images > * {
    width: 28%;
  }
}

.blurb {
  max-width: 600px;
}

`

export default GlobalStyle
