/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { Link } from "gatsby"

import "../css/bootstrap-reboot.min.css"
import "../css/layout.css"

import styled, { ThemeProvider } from "styled-components"

import { nightTheme, defaultTheme } from "../themes/themes"

import GlobalStyle from "../themes/globalStyle"

import Header from "./header"
import Logo from "./logo"
import Nav from "./themableUI/nav"

import Container from "./themableUI/container"

import ThemeButton from "./theme-button"

import Button from "./themableUI/button"

import ReactModal from "react-modal"

const PageNav = styled(Nav)`
  margin-left: auto;
  flex-wrap: nowrap;
`

const StyledContentWrapper = styled(Container)`
  min-height: 100vh;
  padding-top: ${props => props.theme.headerHeight};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
`

const StyledFooter = styled.footer`
  min-height: ${props => props.theme.footerHeight || "  30px"};
  display: flex;
  align-items: center;
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [isLoaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [])

  const [isNightMode, toggleTheme] = useState(false)

  const [activeLink, setLink] = useState("")

  const passFunc = { setLink: str => setLink(str) }

  const [preferenceWasChosen, setPreferenceWasChosen] = useState(false)
  const [animationIsEnabled, setAnimationIsEnabled] = useState(false)

  return (
    <ThemeProvider
      theme={
        isNightMode
          ? {
              ...nightTheme,
              ...passFunc,
              animationIsEnabled: animationIsEnabled,
            }
          : {
              ...defaultTheme,
              ...passFunc,
              animationIsEnabled: animationIsEnabled,
            }
      }
    >
      <GlobalStyle />

      <ReactModal
        isOpen={!preferenceWasChosen}
        style={{
          overlay: {
            zIndex: 2,
          },
        }}
      >
        <p className="aboutText">Welcome to my Portfolio</p>
        <p>
          I just wanted quickly to let you know that
          <b> this site features fast-moving animations.</b> Please enable them
          if you can - I'd appreciate the chance to share them with you. You can
          always change this preference later if you change your mind.
        </p>
        <p>
          <Button
            style={{
              marginBottom: 20,
              marginRight: 20,
              background: defaultTheme.color1Adjust,
              boxShadow: "none",
            }}
            onClick={() => {
              setAnimationIsEnabled(true)
              setPreferenceWasChosen(true)
            }}
          >
            Enable Animation
          </Button>
          <Button
            style={{
              marginBottom: 20,
              marginRight: 20,
              background: defaultTheme.color2Adjust,
              boxShadow: "none",
            }}
            onClick={() => {
              setAnimationIsEnabled(false)
              setPreferenceWasChosen(true)
            }}
          >
            Disable Animation
          </Button>
        </p>
      </ReactModal>

      <div style={isLoaded ? {} : { display: "none" }} className="layout">
        <Header
          dividerIsOpen={false}
          siteTitle={
            data.site.siteMetadata ? data.site.siteMetadata.title : `Title`
          }
        >
          <Logo />
          <PageNav>
            <Link activeClassName="is-active" partiallyActive={true} to="/work">
              work
            </Link>
            <Link
              className={activeLink === "about" ? "is-active" : ""}
              to="/#about"
            >
              about
            </Link>
            <Link
              className={activeLink === "contact" ? "is-active" : ""}
              to="/#contact"
            >
              contact
            </Link>
          </PageNav>
        </Header>

        <StyledContentWrapper>
          <main>{children}</main>

          <StyledFooter>
            <span>© {new Date().getFullYear()}</span>
            {activeLink && (
              <Button
                style={{ marginLeft: "auto", boxShadow: "none" }}
                onClick={() => setAnimationIsEnabled(!animationIsEnabled)}
              >
                {animationIsEnabled ? "Disable Animation" : "Enable Animation"}
              </Button>
            )}
            <ThemeButton
              isNightMode={isNightMode}
              onClick={() => toggleTheme(!isNightMode)}
            ></ThemeButton>
          </StyledFooter>
        </StyledContentWrapper>
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
