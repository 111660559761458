import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import styled from "styled-components"

import { getObjectStyles } from "./themableUI//utilities/themify"

const StyledLogo = styled(Link)`
  ${props => (props.theme.logo ? getObjectStyles(props.theme.logo.styles) : "")}
`

const Logo = () => (
  <StyledLogo to={"/"}>
    <StaticImage
      src="../images/jodicodeslogo.png"
      width={160}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="Xooldra Digital Logo"
      style={{ display: "block" }}
    />
  </StyledLogo>
)

export default Logo
